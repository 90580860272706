.mifalfont {
  font-family: none;
}

.slide-in-left {
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.slide-in-right {
  animation: 0.5s ease-out 0s 1 slideInFromRight;
}

.slide-in-bottom {
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.capitalize {
  text-transform: capitalize;
}
